import React from 'react';
import Image from "next/image";
import Link from "next/link";
// scss
import styles from "./ArticleCard.module.scss";

function ArticleCard({item, findFeatured, readingTime, articleWriterNames}) {
    return (
        (<Link
            href={`/post/${item.node.slug}`}
            locale={false}
            passHref
            className={`${styles.card} card`}>

            <div className={styles.img}>
                {findFeatured && findFeatured(item) ? <span>Featured</span> : null}
                <div className={styles.articleImg}>
                    <Image src={item.node.featuredImage && item.node.featuredImage.node && item.node.featuredImage.node.sourceUrl}
                           alt="Articles Img" title="Articles" layout={"fill"} />
                </div>
            </div>
            <div className={styles.cardBody}>
                <ul className={`${styles.list} d-flex`}>
                    <li>{item.node.data}</li>
                    <li>{readingTime} min read</li>
                </ul>
                <div className={styles.articleTitle}>
                    {item.node.title}
                </div>
                <span className={styles.by}>By {articleWriterNames}</span>
            </div>

        </Link>)
    );
}

export default ArticleCard;