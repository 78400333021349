import React from "react";
// scss
import styles from "./ArticleCard.module.scss";
// mui
import {useRouter} from "next/router";
import ArticleCard from "./articleCard";
 
const ArticleData = (props) => {
    const router = useRouter();

    function filterFeatured(item) {
        return item.node.articleTags.edges.length > 0 ?
            !!item.node.articleTags.edges.filter(obj => {
                return obj.node.name === "Featured article"
            })
            : false;
    }

    function findFeatured(item) {
        return item.node.articleTags.edges.length > 0 ?
            !!item.node.articleTags.edges.find(obj => obj.node.name === "Featured article")
            : null
    }

    return (
        <>
            {props.articles && props.articles.length > 0 ?
                props.articles.map(function (item, index) {
                return (item.node.slug !== router.query.articleDetail
                    && filterFeatured(item)) && (
                        <ArticleCard
                            key={item.node.slug}
                            item={item}
                            findFeatured={findFeatured}
                            // styles={styles}
                            readingTime={props.readingTime && props.readingTime.length > 0 ? props.readingTime[index] : 0}
                            articleWriterNames={props.articleWriterNames && props.articleWriterNames.length > 0  ? props.articleWriterNames[index] : ""}
                        />
                )
            }) : <div className="noReview-found">No article found</div>}

            {props.articles && props.articles.length > 0 ?
                props.articles.map(function (item, index) {
                    return (item.node.slug !== router.query.articleDetail
                        && !filterFeatured(item)) && (
                            <ArticleCard key={item.node.slug}
                                         item={item}
                                         styles={styles}
                                         readingTime={props.readingTime && props.readingTime.length > 0 && props.readingTime[index] ? props.readingTime[index] : 0}
                                         articleWriterNames={props.articleWriterNames && props.articleWriterNames.length > 0 ? props.articleWriterNames[index] : ""}
                            />
                    )
                }) : null}
        </>
    );
}

export default ArticleData;